import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IStateUser } from './interfaces';
import { getContractorInfo } from '../api/getContractorInfo';
import {setLoading, setToken} from "./jwt";

const initialState: IStateUser = {
	value: null,
	loading: false,
	clipboard: { text: '' },
	message: { type: '', text: '' },
	error: '',
};

export const profileFields = [
	{ type: 'ContractorId', value: 'user.id', editable: false },
	{ type: 'ContractorName', value: 'user.full', editable: false },
	{ type: 'ContractorUNN', value: 'user.unn', editable: false },
	{ type: 'ContractorAddress', value: 'user.legal', editable: false },
	{ type: 'ContractorTelefon1', value: 'user.contact', editable: false },
	{ type: 'LicenseNumber', value: 'user.licenseID', editable: false },
	{ type: 'LicenseDateBegin', value: 'user.licenseDate', editable: false },
	{ type: 'LicenseDateEnd', value: 'user.licenseExpiration', editable: false },
	{ type: 'LicenseType', value: 'user.licenseType', editable: false },
	{ type: 'BankName', value: 'user.nameBank', editable: false },
	{ type: 'BankAccountNumber', value: 'user.counterpartyAcc', editable: false },
	{ type: 'BankBIC', value: 'user.bic', editable: false },
	{ type: 'ContractNumber', value: 'user.agreementNumber', editable: false },
	{ type: 'ContractDateBegin', value: 'user.agreementFrom', editable: false },
	{ type: 'ContractDateEnd', value: 'user.agreementUntil', editable: false },
	{ type: 'CertificateNumber', value: 'user.certificateNumber', editable: false },
	{ type: 'CertificateDate', value: 'user.dateCertificate', editable: false },
	{ type: 'Name1', value: 'user.nameRepresentative', editable: false },
	{ type: 'Name2', value: 'user.fullNameRepresentative', editable: false },
	{ type: 'Name3', value: 'user.middleNameRepresentative', editable: false },
	{ type: 'Phone1', value: 'user.mobilePhone', editable: false },
	{ type: 'EMail', value: 'user.Email', editable: false },
	{ type: 'MarkerKPI', value: 'user.KPI', editable: false },
];

export const fetchContractorInfo = createAsyncThunk('users/fetchContractor', async (token: string) => {
	return await getContractorInfo(token);
});

const user = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setClipboard: (state, action) => {
			state.clipboard = { ...state.clipboard, ...action.payload };
		},
		setContractor: (state, action) => {
			state.value = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchContractorInfo.fulfilled, (state, action) => {
			const contractorData = action.payload[0];

			if (contractorData.ContractorId && contractorData.ContractorName) {
				localStorage.setItem('contractor', JSON.stringify(contractorData));
				localStorage.setItem('userName', contractorData.ContractorName);
				localStorage.setItem('userId', contractorData.ContractorId);
			}
		})
	}
});

export const { setClipboard, setContractor } = user.actions;
export default user.reducer;
