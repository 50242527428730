import { Nullable, TError } from 'services/store/interfaces';
enum METHOD {
	GET = 'GET',
	POST = 'POST',
}

/* eslint-disable @typescript-eslint/no-explicit-any */
type TRequest = <T>(methodName: string, data?: any, token?: Nullable<string>) => Promise<T>;
interface IResponse<T> {
	Table: T | TError[];
	Table2: T | TError[];
}

const transport = async <T>(
	method: string,
	methodName: string,
	data: Record<string, unknown> = {},
	token?: string
): Promise<T> => {
	const cookieToken = token || null;
	const body = {
		CRC: '',
		Packet: {
			MethodName: methodName,
			JWT: token || cookieToken,
			ServiceNumber: process.env.REACT_APP_SERVICE_NUMBER,
			Data: data,
		},
	};

	const path = `${process.env.REACT_APP_DOMAIN}/${process.env.REACT_APP_PORT}/Json`;

	const response = await fetch(path, {
		method,
		body: JSON.stringify(body),
	});

	const json: IResponse<T> = await response.json();
	const table = json.Table;
	const table2 = json.Table2;

	if ((table as TError[])[0] && [50004, 50003].includes((table as TError[])[0].Error)) {
		console.log('error  50003 or 50004');
		localStorage.clear();
		window.location.href = '/';
	}

	return table2 ? ({ table, table2 } as T) : (table as T);
};

export const request = {
	get: transport.bind(this, METHOD.GET) as TRequest,
	post: transport.bind(this, METHOD.POST) as TRequest,
};
