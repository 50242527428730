import React, { Fragment, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './userMenu.module.scss';
import { Menu } from 'components/Menu';
import { Callto } from '../Callto';
import { Support } from './Support';
import { Logout } from './Logout';
import { Personal } from './Personal';
import classNames from 'classnames';
import { sendSupportRequest } from 'services/api/sendSupportRequest';
import { IStoreState } from 'services/store/interfaces';
import { useIsAdminSession } from 'services/hooks/useIsAdminSession';
import { fetchCleanToken } from 'services/store/jwt';
import { Tutorial } from '../Tutorial';
import { Links } from 'components/Personal';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'services/contexts/languageContext';

export const UserMenu = () => {
	const language = useLanguage();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [messageSupport, setMessageSupport] = useState({ type: '', message: '' });
	const isAdmin = useSelector((state: IStoreState) => state.support.isAdmin);
	const [isAdminSession] = useIsAdminSession();

	const isSuccessSupport = messageSupport.type === 'success';
	const isWarningSupport = messageSupport.type === 'warning';

	const logout = useCallback(() => {
		dispatch(fetchCleanToken({ IsDelete: '1' }));
	}, []);

	const onSupport = useCallback(() => {
		sendSupportRequest(language === 'ru' ? 1 : 2).then((res) => {
			if (res[0].Result) setMessageSupport({ type: 'success', message: res[0].Result });
			if (res[0].ErrorDescription) setMessageSupport({ type: 'warning', message: res[0].ErrorDescription });
		});
	}, [language]);

	return (
		<Fragment>
			<div className={styles.userMenu}>
				<div className={styles.header}>
					<div className={styles.title}>
						{t('rightMenu.hello')}, {localStorage.userName}!
					</div>
					<div className={styles.id}>id: {localStorage.userId}</div>
				</div>

				<div className={styles.userMenu_content}>
					<Menu type="submenu" />

					<div className={styles.list}>
						<Personal link="/profile" title={t('rightMenu.personal')} />

						{!isAdmin && !isAdminSession && <Support title={t('rightMenu.support')} onClick={onSupport} />}

						<Logout title={t('rightMenu.logout')} onClick={logout} />
					</div>

					<div className={styles.phone}>
						<p
							className={classNames(styles.list__support, {
								[styles.list__support_success]: isSuccessSupport,
								[styles.list__support_warning]: isWarningSupport,
							})}
						>
							{messageSupport.message}
						</p>

						<p className={styles.phone_text}>{t('rightMenu.email')}</p>
						<Callto type="email" email="zakaz_support@eurotorg.by">
							zakaz_support@eurotorg.by
						</Callto>
						<p className={styles.phone_time}>{t('rightMenu.hours')} 9:00-18:00</p>
					</div>

					<Tutorial />
					<Links mix={styles.submenu__cookies} />
				</div>
			</div>
		</Fragment>
	);
};
