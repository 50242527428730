import React, { Fragment, useEffect, useState, useCallback, CSSProperties } from 'react';
import styles from './filterOrders.module.scss';
import classNames from 'classnames';
import { InputText } from 'components/Common/Input/InputText';
import { IStoreState, TFilterOrders, TResultBlock } from 'services/store/interfaces';
import { getArrFilter } from 'services/utils/js/getArrFilter';
import 'dayjs/locale/ru';
import localeRu from 'antd/es/date-picker/locale/ru_RU';
import localeEn from 'antd/es/date-picker/locale/en_US';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useDispatch, useSelector } from 'react-redux';
import { InputSelect, TSelectField } from 'components/Common/Input/InputSelect';
import { getCheckxArr } from 'services/utils/js/getCheckxArr';
import { checkX } from 'services/store/orders';
import { Address } from 'components/Address/Address';
import { Actions } from 'components/Filter/Actions';
import { Button } from 'components/Filter/Actions/Button';
import { clearOrders, defaultListOrders, hideOrdersFilter } from 'services/store/filter';
import { getDateFilter, getDateMountedFilter } from 'services/utils/js/getFilterDate';
import { ReactComponent as Icon } from 'assets/images/filter/calendar.svg';
import { getAddressLinerStyles, getLinerStyles } from 'services/utils/js/getLInersFilterStyles';
import { setAddressDefault } from 'services/store/address';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'services/contexts/languageContext';

dayjs.extend(customParseFormat);
interface IFilterOrdersProps {
	onChange?: (filters: TFilterOrders) => void;
	onSet?: () => void;
}

const optionCheckx = [...getCheckxArr(checkX)];
const resultRegions = {
	RegionId: false,
	DistrictId: false,
	CityId: false,
};
const defaultResult = {
	...resultRegions,
	Checkx: false,
};

export const FilterOrders = ({ onChange = () => {}, onSet = () => {} }: IFilterOrdersProps) => {
	const language = useLanguage();
	const { t } = useTranslation();

	const address = useSelector((state: IStoreState) => state.address);
	const isCleanOrders = useSelector((state: IStoreState) => state.filter.isCleanOrders);
	const filters = useSelector((state: IStoreState) => state.filter.listOrders);
	const isShowFilter = useSelector((state: IStoreState) => state.filter.isShowOrders);
	const breakpoints = useBreakpoint();
	const isMobile = breakpoints.includes('xs');
	const isDesktop = !['', 'xs', 'xxs'].includes(breakpoints);

	const [fields, setFilters] = useState<TFilterOrders>(
		isCleanOrders
			? defaultListOrders
			: {
					...filters,
					DateBegin: getDateFilter(String(filters.DateBegin)),
					DateEnd: getDateFilter(String(filters.DateEnd)),
			  }
	);

	const [isHidden, setIsHidden] = useState<boolean | string>(true);
	const [isChangeBegin, setClearBegin] = useState(defaultListOrders.DateBegin !== fields.DateBegin);
	const [isChangeEnd, setClearEnd] = useState(defaultListOrders.DateEnd !== fields.DateEnd);
	const [isCities, setIsCities] = useState(false);
	const [resultBlocks, setResultBlock] = useState<TResultBlock>(defaultResult);
	const { RegionId, DistrictId, CityId, Checkx } = resultBlocks;
	const dispatch = useDispatch();

	const changeFilters = (id: string, value: string) => {
		setFilters({ ...fields, [id]: getArrFilter(value) });
	};

	const changeRegion = (values: TSelectField[]) => {
		const RegionId = values.length > 0 ? values.map(({ id }) => id) : [];
		setFilters({ ...fields, RegionId });
	};

	const changeDistrict = (values: TSelectField[]) => {
		const DistrictId = values.length > 0 ? values.map(({ id }) => id) : [];
		setFilters({ ...fields, DistrictId });
	};

	const changeCities = (values: TSelectField[]) => {
		const CityId = values.length > 0 ? values.map(({ id }) => id) : [];
		setFilters({ ...fields, CityId });
	};

	const changeChecks = (values: TSelectField[]) => {
		const Checkx = values.length > 0 ? values.map(({ id }) => id) : [];

		setFilters({ ...fields, Checkx });
	};

	const onSetBegin = useCallback(
		(value: unknown, dateString: string) => {
			setFilters({ ...fields, DateBegin: dateString });
			setClearBegin(true);
		},
		[[filters.DateBegin]]
	);

	const onSetEnd = useCallback(
		(value: unknown, dateString: string) => {
			const isEmptyBegin = fields.DateBegin === '';
			const isEmptyEnd = dateString === '';
			const DateEnd = isEmptyBegin ? '' : isEmptyEnd ? dayjs().toISOString().slice(0, 10) : dateString;

			setFilters({ ...fields, DateEnd });
			setClearEnd(true);
		},
		[[filters.DateEnd]]
	);

	useEffect(() => {
		if (
			(isDesktop &&
				JSON.stringify(filters) !==
					JSON.stringify({
						...fields,
						DateBegin: getDateFilter(String(filters.DateBegin)),
						DateEnd: getDateFilter(String(filters.DateEnd)),
					})) ||
			isCleanOrders
		)
			onClear();
	}, [isShowFilter, isCleanOrders]);

	const onClear = () => {
		dispatch(clearOrders());
		dispatch(setAddressDefault());
		setFilters({ ...defaultListOrders });
		dispatch(clearOrders());
		setResultBlock(defaultResult);

		setClearBegin(false);
		setClearEnd(false);
		setIsHidden(true);
	};

	const handlerClose = () => dispatch(hideOrdersFilter());
	const onResultBlock = (value: TResultBlock) => setResultBlock({ ...resultBlocks, ...value });

	useEffect(() => {
		const DateBegin = getDateFilter(String(fields.DateBegin));
		const DateEnd = getDateFilter(String(fields.DateEnd));

		onChange({ ...fields, DateBegin, DateEnd });
	}, [JSON.stringify(fields)]);

	useEffect(() => {
		setFilters({
			...fields,
			DateBegin: getDateMountedFilter(filters.DateBegin || ''),
			DateEnd: getDateMountedFilter(filters.DateEnd || ''),
		});
	}, []);

	useEffect(() => {
		onResultBlock(resultRegions);
		setIsCities(false);
	}, [JSON.stringify(fields.RegionId)]);

	useEffect(() => {
		onResultBlock({ CityId: false });
		setIsCities(fields?.DistrictId?.length !== 0);
	}, [JSON.stringify(fields?.DistrictId)]);

	return (
		<Fragment>
			<div className={styles.filter}>
				<div className={classNames(styles.left)}>
					<div className={styles.item}>
						<span className={styles.item_label}>{t('orders.number')}</span>

						<InputText
							onChange={(value) => changeFilters('OrderBuyGroupId', value)}
							value={fields.OrderBuyGroupId?.join()}
						/>
					</div>

					<div
						className={styles.item_liner}
						style={RegionId ? ({ height: RegionId } as CSSProperties) : { height: 0.1 }}
					/>

					<div className={styles.item}>
						<span className={styles.item_label}>{t('filters.orderComp.period')}</span>

						<div className={styles.item_line}>
							<DatePicker
								key="uniqstart"
								value={isChangeBegin ? dayjs(fields.DateBegin, 'DD.MM.YY') : undefined}
								onChange={onSetBegin}
								className={classNames(styles.item_line__item, {
									[styles.active]: isChangeBegin,
									[styles.eng]: language !== 'ru',
								})}
								placeholder=""
								locale={language === 'ru' ? localeRu : localeEn}
								allowClear={false}
								format={'DD.MM.YY'}
								suffixIcon={<Icon />}
								showToday={false}
							/>

							<DatePicker
								key="uniqend"
								value={isChangeEnd ? dayjs(fields.DateEnd, 'DD.MM.YY') : undefined}
								onChange={onSetEnd}
								className={classNames(styles.item_line__item, {
									[styles.active]: isChangeEnd,
									[styles.eng]: language !== 'ru',
								})}
								placeholder=""
								locale={language === 'ru' ? localeRu : localeEn}
								allowClear={false}
								format={'DD.MM.YY'}
								suffixIcon={<Icon />}
								showToday={false}
							/>
						</div>
					</div>

					<div
						className={styles.item_liner}
						style={DistrictId ? ({ height: DistrictId } as CSSProperties) : { height: 0.1 }}
					/>

					<div className={styles.item}>
						<span className={styles.item_label}>{t('status.order')}</span>

						<InputSelect
							title="Checkx"
							initial={{ id: '0', text: t('status.choice') }}
							options={optionCheckx.map((item) => ({ ...item, text: `${t(item.text)} (${item.id})` }))}
							values={address.Checkx}
							onChange={changeChecks}
							onResultBlock={onResultBlock}
						/>
					</div>

					<div className={styles.item_liner} style={getLinerStyles(Checkx, CityId)} />

					<div
						className={classNames(styles.item, {
							[styles.isHidden]: isHidden,
						})}
					>
						<span className={styles.item_label}>{t('filters.orderComp.storage')}</span>
						<InputText onChange={(value) => changeFilters('WarehouseId', value)} value={fields.WarehouseId?.join()} />
					</div>
				</div>

				<div className={classNames(styles.right)}>
					<Address
						setRegion={changeRegion}
						setDistrict={changeDistrict}
						setCities={changeCities}
						onResultBlock={onResultBlock}
						onSetHidden={isMobile ? undefined : setIsHidden}
						isEmpty={fields?.RegionId?.length === 0}
					/>

					<div className={styles.item_liner} style={getAddressLinerStyles(isCities, Checkx, CityId)} />

					<div className={classNames(styles.item)}>
						<span className={styles.item_label}>{t('filters.orderComp.articles')}</span>
						<InputText onChange={(value) => changeFilters('GoodsId', value)} value={fields.GoodsId?.join()} />
					</div>

					<div
						className={classNames(styles.item, {
							[styles.isHidden]: !isHidden,
							[styles.isDisable]: isHidden === 'hidden',
						})}
					>
						<span className={styles.item_label}>{t('filters.orderComp.storage')}</span>
						<InputText onChange={(value) => changeFilters('WarehouseId', value)} value={fields.WarehouseId?.join()} />
					</div>
				</div>
			</div>

			<Actions
				clearButton={
					<Button title={`${t('actions.clean')} ${isMobile ? t('filters.filter') : ''}`} onClick={onClear} />
				}
				confirmButton={<Button title={`${t('actions.use')} ${isMobile ? t('filters.filter') : ''}`} onClick={onSet} />}
				closeButton={<Button title={t('actions.close')} onClick={handlerClose} />}
			/>
		</Fragment>
	);
};
