import React, { Fragment, useEffect, useState } from 'react';
import styles from './form.module.scss';
import { fetchAuth } from 'services/store/jwt';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { getValue } from 'services/utils/react/pickFromSyntheticEvent';
import { Loader } from 'components/Common/Loading/Loader';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

export const Form = () => {
	const { t } = useTranslation();
	const [name, setName] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState('');

	const error = useSelector((state: IStoreState) => state.jwt.error);
	const loading = useSelector((state: IStoreState) => state.jwt.loading);
	const dispatch = useDispatch();

	useEffect(() => setErrors(error), [error]);

	const handlerSubmit = (name: string, password: string): void => {
		const payload = {
			LoginName: name,
			Password: password,
			LoginNameTypeId: 1,
			SourceName: process.env.REACT_APP_SOURCE_NAME || '',
			UserAgent: navigator.userAgent,
			UUID: uuidv4(),
		};

		dispatch(fetchAuth(payload));
	};

	return (
		<form
			className={styles.form}
			onSubmit={(e) => {
				e.preventDefault();
				handlerSubmit(name, password);
			}}
		>
			<div className={styles.form__header}>{t('home.loginAccount')}</div>

			<div className={styles.container}>
				<label htmlFor="uname" className={styles.form__label}>
					{t('home.name')}
				</label>

				<input
					value={name}
					type="text"
					onChange={getValue((value) => {
						setName(value);
						setErrors('');
					})}
					name="uname"
					className={styles.form__input}
				/>

				<label htmlFor="psw" className={styles.form__label}>
					{t('home.password')}
				</label>

				<input
					type="password"
					value={password}
					onChange={getValue((value) => {
						setPassword(value);
						setErrors('');
					})}
					name="psw"
					className={styles.form__input}
				/>

				<div
					className={classNames(styles.form__error, {
						[styles.form__error_visible]: errors.length > 0,
					})}
				>
					{errors.length > 0 && <img src="/assets/icons/error.svg" alt="error" className={styles.form__error_icon} />}
					{t(errors)}
				</div>

				<div className={styles.form__buttons}>
					<button
						type="submit"
						className={classNames(styles.form__button, {
							[styles.form__button_error]: errors.length > 0,
							[styles.form__button_loading]: loading,
						})}
						disabled={loading || errors.length > 0}
					>
						{loading ? (
							<Fragment>
								<Loader isLogin={true} />
								{t('home.in')}
							</Fragment>
						) : (
							t('home.in')
						)}
					</button>
					{/*<button className={styles.form__button}>*/}
					{/*	<Link to="/registration">Регистрация</Link>*/}
					{/*</button>*/}
				</div>
			</div>
		</form>
	);
};
